import React from 'react'
import PropTypes from 'prop-types'
import {
  graphql
} from 'gatsby'
import Layout from '../components/Layout'
import Content, {
  HTMLContent
} from '../components/Content'


export const WinePageTemplate = () => {
  console.log(styles);
  return ( <
    div >
    <
    div className = "full-width-image-container margin-top-0"
    style = {
      {
        backgroundImage: `url('/img/wine-index.jpg')`,
      }
    } >
    <
    h1 className = "has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
    style = {
      {
        color: 'white',
        lineHeight: '1',
        padding: '0.25em',
        fontFamily: 'Lulo'
      }
    } >
    Wine <
    /h1> < /
    div > <
    section className = "section" >
    <
    div className = "row"
    id = "wine" >
    <
    div className = "column"
    id = "container" >
    <
    ul >
    <
    li style = {
      styles.wine
    } > Italy | SPARKLING MONVIN PROSECCO < /li> <
    li style = {
      styles.wine
    } > Austria | TEGERNSEERHOF FRANZ MITTELBACH ROSE ZWEIGELT < /li> <
    li style = {
      styles.wine
    } > Italy | GIULIANO ROSATI PINOT GRIGIO < /li> <
    li style = {
      styles.wine
    } > Spain | VINA ALMERIA ALBARINO < /li> <
    li style = {
      styles.wine
    } > Germany | LEITZ WEINGUT DRAGON STONE RIESLING < /li> <
    li style = {
      styles.wine
    } > USA | MATTHEW FRITZ CHARDONNAY < /li> <
    li style = {
      styles.wine
    } > Austria | TEGERNSEERHOF "T-26" GRUNER VELTLINER  < /li> <
    li style = {
      styles.wine
    } > New Zealand | LOBSTER REEF SAUVIGNON BLANC < /li> <
    li style = {
      styles.wine
    } > Argentina | MAIPE MALBEC < /li> <
    li style = {
      styles.wine
    } > USA | LA STORIA ZINFANDEL < /li> <
    li style = {
      styles.wine
    } > USA | HYLAND ESTATES OLD VINE PINOT NOIR < /li> <
    li style = {
      styles.wine
    } > USA | VINA ROBLES CABERNET SAUVIGNON < /li> <
    li style = {
      styles.wine
    } > Italy | CASTELLARE CHIANTI CLASSICO < /li> <
    li style = {
      styles.wine
    } > * By the glass < /li> < /
    ul > <
    /div> < /
    div > <
    /section>    < /
    div >

  )
}

const styles = {
  wine: {
    fontWeight: 200,
    fontFamily: "Lulo",
    textAlign: "center",
    fontSize: 25
  }
}

// WinePageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// }

const WinePage = () => {
  return ( <
    Layout >
    <
    WinePageTemplate /
    >
    <
    /Layout>
  )
}


export default WinePage;
